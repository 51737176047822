<template>
    <ComplexDialog
        :title="title + ': Step ' + step"
        v-model="visible"
        :max-width="1000"
        :close-confirmation="customer"
    >
        <v-window v-model="step">
            <v-window-item :value="1">
                <v-row dense>
                    <v-col cols="12">
                        <p class="subtitle-1 text--primary">
                            Which customer is this transfer request for?
                        </p>
                        <CustomerDropdown
                            v-if="visible"
                            :id="33"
                            label="Customer"
                            @update="updateCustomer"
                        />
                    </v-col>
                    <v-expand-transition>
                        <v-col cols="12" v-if="customer">
                            <p class="subtitle-1 text--primary">
                                Which trunk are the numbers being transferred
                                to?
                            </p>
                            <TrunkDropdown
                                v-if="customer"
                                :customer-id="
                                    customerId
                                        ? customerId
                                        : customer
                                        ? customer.i_customer
                                        : null
                                "
                                allow-dropdown
                                label="Trunk"
                                @update="updateTrunk"
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" v-if="trunk">
                            <p class="subtitle-1 text--primary">
                                What type of transfer request is this?
                            </p>
                            <v-radio-group v-model="transferType">
                                <v-radio :value="1">
                                    <template v-slot:label>
                                        <span class="text--primary pr-2"
                                            >Number Port</span
                                        >Bring a number from another provider to
                                        Breeze Connect
                                    </template>
                                </v-radio>
                                <v-radio :value="2">
                                    <template v-slot:label>
                                        <span class="text--primary pr-2"
                                            >Migration</span
                                        >Migrate existing service from Breeze
                                        1.0 or Breeze 1.5
                                    </template>
                                </v-radio>
                                <v-radio :value="3">
                                    <template v-slot:label>
                                        <span class="text--primary pr-2"
                                            >Smart Number</span
                                        >Activate a newly purchased 13/1300/1800
                                        number
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-expand-transition>
                </v-row>
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense>
                    <v-col cols="12" v-if="transferType === 1">
                        <p class="subtitle-1 text--primary">
                            Who is the current service provider?
                        </p>
                        <v-combobox
                            v-model="carrier"
                            autofocus
                            outlined
                            label="Current Service Provider"
                            hint="You can type the name of the service provider if is not in the list"
                            :items="carrierList"
                        />
                        <p class="subtitle-1 text--primary">
                            What is the account number?
                        </p>
                        <v-text-field
                            v-model="accountNumber"
                            label="Account Number"
                            hint="This can be found on the customer's phone account"
                            outlined
                        />
                    </v-col>
                    <v-expand-transition>
                        <v-col
                            cols="12"
                            v-if="
                                transferType === 1 &&
                                accountNumber &&
                                carrier &&
                                !editCustomerDetail
                            "
                            class="subtitle-1"
                        >
                            <p class="text--primary">
                                We have the following details on record for this
                                customer:
                            </p>
                            <v-sheet
                                class="py-2 px-4"
                                v-bind:class="{
                                    'grey lighten-3': !$vuetify.theme.dark,
                                    'grey darken-3': $vuetify.theme.dark,
                                }"
                            >
                                <span class="font-weight-bold"
                                    >Account Name: </span
                                >{{ trunk.companyname }}<br />
                                <span class="font-weight-bold">Address: </span>
                                <Address :data="trunk" /><br />
                                <span class="font-weight-bold">Contact: </span
                                >{{ customer.firstname }} {{ customer.lastname
                                }}<br />
                                <span class="font-weight-bold">Phone: </span
                                >{{ customer.phone1 }}<br />
                                <span class="font-weight-bold">Phone 2: </span
                                >{{ customer.phone2 }}<br />
                                <span class="font-weight-bold">Email: </span
                                >{{ trunk.email }}
                            </v-sheet>
                            <p class="subtitle-1 text--primary pt-4">
                                Do these details match the current service
                                provider bill?
                            </p>
                            <v-radio-group
                                v-model="editCustomerDetail"
                                class="text--primary"
                            >
                                <v-radio :value="false" label="Yes" />
                                <v-radio :value="true" label="No" />
                            </v-radio-group>
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" v-if="editCustomerDetail">
                            <p class="subtitle-1 text--primary">
                                Please enter the customer's details. These
                                should match what is on the customer's current
                                phone bill.
                            </p>
                            <v-text-field
                                hide-details
                                v-model="trunk.companyname"
                                label="Account Name *"
                                outlined
                            /> </v-col
                        >S
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" v-if="editCustomerDetail">
                            <v-text-field
                                hide-details
                                v-model="trunk.baddr1"
                                label="Address Line 1 *"
                                outlined
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" v-if="editCustomerDetail">
                            <v-text-field
                                hide-details
                                v-model="trunk.address_line_2"
                                label="Address Line 2"
                                outlined
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" md="6" v-if="editCustomerDetail">
                            <v-text-field
                                v-model="trunk.city"
                                label="Suburb *"
                                outlined
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="6" md="3" v-if="editCustomerDetail">
                            <v-select
                                outlined
                                :items="statesList"
                                item-text="short"
                                item-value="name"
                                label="State"
                                v-model="state"
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="6" md="3" v-if="editCustomerDetail">
                            <v-text-field
                                v-model="trunk.zip"
                                label="Suburb *"
                                outlined
                            />
                        </v-col>
                    </v-expand-transition>
                    <v-col cols="12" v-if="transferType === 2">
                        <p class="subtitle-1 text--primary">
                            Which platform are you migrating from?
                        </p>
                        <v-radio-group v-model="migrationType">
                            <v-radio :value="1">
                                <template v-slot:label>
                                    <span class="text--primary pr-2"
                                        >Breeze 1.0</span
                                    >
                                </template>
                            </v-radio>
                            <v-radio :value="2">
                                <template v-slot:label>
                                    <span class="text--primary pr-2"
                                        >Breeze 1.5</span
                                    >NetSIP services
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                    <v-expand-transition>
                        <v-col cols="12" v-if="migrationType === 1">
                            <v-alert type="info" outlined
                                >Number porting will be required for this
                                migration. No port form is required.</v-alert
                            >
                        </v-col>
                    </v-expand-transition>
                    <v-expand-transition>
                        <v-col cols="12" v-if="migrationType">
                            <p class="subtitle-1 text--primary">
                                Please enter the current
                                <span v-if="migrationType === 1"
                                    >Breeze Connect</span
                                ><span v-if="migrationType === 2">NetSIP</span>
                                Trunk ID
                            </p>
                            <v-text-field
                                v-model="accountNumber"
                                label="Trunk ID"
                                outlined
                            />
                        </v-col>
                    </v-expand-transition>
                </v-row>
            </v-window-item>
            <v-window-item :value="3">
                <DIDListInput :porting="transferType === 1" />
            </v-window-item>
            <v-window-item :value="4"> Final Step </v-window-item>
        </v-window>

        <template v-slot:actions>
            <ButtonWithTooltip
                text="Back"
                @click="step--"
                v-if="step > 1"
                flat
            />
            <v-spacer />
            <ButtonWithTooltip
                text="Next"
                @click="step++"
                color="primary"
                :disabled="
                    (step === 1 && !transferType) ||
                    (step === 2 &&
                        !migrationType &&
                        editCustomerDetail === null)
                "
                flat
            />
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import CustomerDropdown from '../../pieces/Forms/CustomerDropdown';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import DIDListInput from '../../pieces/DID/DIDListInput';
import TrunkDropdown from '../../pieces/Forms/TrunkDropdown';
import Address from '../../pieces/Address';
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '../../../mixins/dialogMixin';
export default {
    name: 'NewTransferDialog',
    components: {
        Address,
        TrunkDropdown,
        DIDListInput,
        ButtonWithTooltip,
        CustomerDropdown,
        ComplexDialog,
    },
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        step: 1,
        isValid: false,
        customer: null,
        trunk: null,
        transferType: null,
        carrier: null,
        accountNumber: null,
        migrationType: false,
        showConfirmClose: false,
        editCustomerDetail: null,
        skipDIDs: false,
        statesList: [
            { name: 'Australian Capital Territory', short: 'ACT' },
            { name: 'New South Wales', short: 'NSW' },
            { name: 'Northern Territory', short: 'NT' },
            { name: 'Queensland', short: 'QLD' },
            { name: 'South Australia', short: 'SA' },
            { name: 'Tasmania', short: 'TAS' },
            { name: 'Victoria', short: 'VIC' },
            { name: 'Western Australia', short: 'WA' },
        ],
        carrierList: ['Telstra', 'Optus', 'Commander'],
    }),
    methods: {
        updateCustomer(data) {
            this.customer = data;
            if (!data) {
                this.transferType = null;
            }
        },
        updateTrunk(data) {
            this.trunk = data;
            if (!data) {
                this.transferType = null;
            }
        },
    },
    watch: {
        customer: function () {
            this.transferType = null;
        },
        transferType: function (val) {
            if (val !== 1) {
                this.carrier = null;
                this.accountNumber = null;
            }
            if (val !== 2) {
                this.migrationType = null;
            }
            this.editCustomerDetail = null;
        },
        visible(value) {
            if (!value) {
                this.step = 1;
                this.customer = null;
                this.trunk = null;
            }
        }
    },
    props: {
        title: {
            type: String,
            default: 'New Transfer Request',
        },
        customerId: {
            default: null,
        },
        partnerId: {
            default: null,
        },
    },
};
</script>

<style scoped></style>
