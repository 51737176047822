<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-actions>
                        <v-card-text class="pa-2">Filters</v-card-text>
                        <v-card-text class="pa-2 float-right text-right">
                            Show:
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn small v-on="on" class="ml-2">
                                        {{ filter.status.option }}
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list>
                                        <v-list-item
                                            v-for="(
                                                number, index
                                            ) in filter.statusOptions"
                                            :key="index"
                                            @click="updateStatus(number)"
                                        >
                                            <v-list-item-title>{{
                                                number.option
                                            }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-list>
                            </v-menu>
                        </v-card-text>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <ButtonWithTooltip
                            text="New transfer request"
                            @click="showDialog = true"
                        />
                        <br />
                        <DIDListInput />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <NewTransferDialog v-model="showDialog" />
    </v-container>
</template>

<script>
import DIDListInput from '../../components/pieces/DID/DIDListInput';
import ButtonWithTooltip from '../../components/pieces/ButtonWithTooltip';
import NewTransferDialog from '../../components/dialogs/Transfer/NewTransferDialog';
export default {
    name: 'TransferList',
    data: () => ({
        filter: {
            statusOptions: [
                { option: 'Active', value: 'y' },
                { option: 'All', value: 'n' },
            ],
            status: { option: 'Active', value: 'y' },
        },
        loading: false,
        showDialog: false,
    }),
    components: {
        NewTransferDialog,
        ButtonWithTooltip,
        DIDListInput,
    },
    methods: {
        closeImport() {
            this.showImport = false;
        },
        updateStatus(data) {
            this.filter.status = data;
        },
    },
    created() {},
};
</script>
