<template>
    <span>
        <span v-if="data.baddr1">{{ data.baddr1 }}, </span>
        <span v-if="data.address_line_2">{{ data.address_line_2 }}, </span>
        <span v-if="data.city">{{ data.city }} </span>
        <span v-if="data.state">{{ data.state }} </span>
        <span v-if="data.zip">{{ data.zip }}</span>
        <span v-if="!data">None listed</span>
    </span>
</template>

<script>
export default {
    name: 'Address',
    props: {
        data: {
            default: null,
        },
    },
};
</script>
